@use './color.module';

@mixin focus-base($outline-offset: 2px) {
  &::before,
  &::after {
    content: '';

    position: absolute;
    z-index: 10000;
    inset: 0;

    display: block;

    outline-style: solid;
    outline-width: 2px;
  }

  &::before {
    outline-color: color.$white;
    outline-offset: $outline-offset;
  }

  &::after {
    outline-color: color.$black;
    outline-offset: $outline-offset + 2px;
  }
}

@mixin focus($outline-offset: 2px) {
  position: relative;
  outline: none;

  &:focus-visible {
    @include focus-base($outline-offset);
  }
}

@mixin field-focus($outline-offset: 2px) {
  position: relative;
  outline: none;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    outline: 2px solid color.$white;
    outline-offset: $outline-offset;
  }

  &::after {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    outline: 2px solid color.$black;
    outline-offset: $outline-offset + 2px;
  }
}

@mixin sr-only {
  position: absolute;

  overflow: hidden;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  white-space: nowrap;

  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
}
