@use '~/ui/styles/breakpoints.module';
@use '~/ui/styles/utils.module';

.root {
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (min-width: breakpoints.$md) {
    margin-bottom: 32px;
  }
}

.controls {
  display: flex;
  gap: 10px;

  button {
    @include utils.focus(0);

    padding: 0;
    transition: color 0.3s;
  }

  button:not(:global(.swiper-button-lock)) {
    display: grid;
    place-content: center;
  }

  // this offsets the expanding arrow so it has room to grow without going over the grid line. 4px
  //  is 20% (expanding arrow animation distance) of 20px (icon size)
  button:first-child {
    padding-left: 4px;
  }

  button:last-child {
    padding-right: 4px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.swiper {
  overflow: visible;
  flex-wrap: nowrap;
  max-width: none;

  .news & {
    height: 316px;

    @media (min-width: breakpoints.$lg) {
      height: 366px;
    }
  }

  .grants & {
    height: 252px;

    @media (min-width: breakpoints.$lg) {
      height: 274px;
    }
  }

  .highlight & {
    height: 414px;

    @media (min-width: breakpoints.$md) {
      height: 449px;
    }

    @media (min-width: breakpoints.$lg) {
      height: 520px;
    }
  }
}

.slide[class] {
  height: unset;

  &:has(*:focus-visible) {
    z-index: 2;
  }

  > * {
    box-sizing: border-box;
    // while not likely, some cards have fixed widths/heights. We want the card to fill the carousel
    max-width: unset;
    height: 100%;
    max-height: unset;

    background-color: color-mix(in srgb, var(--theme-primary), #000 var(--shade, 0%));
  }
}

.cta {
  margin-top: 24px;

  @media (min-width: breakpoints.$md) {
    margin-top: 32px;
  }
}
